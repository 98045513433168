import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { setCompliancePolicyStatus } from '@/api/homepage'

import { Cross, InfoIcon } from '@/assets/icons'
const COMPLIANCE_TEXT =
    'This is a reminder that we update our legal terms periodically. Please confirm that you agree to our updated'

const ComplianceBanner = ({ setShowBanner }: { setShowBanner: Dispatch<SetStateAction<boolean>> }) => {
    const [timeoutInstance, setTimeoutInstance] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowBanner(false)
            setCompliancePolicyStatus({ action: 'TIMEOUT' })
        }, 10000)
        setTimeoutInstance(timeout)
    }, [])

    const handleLinkClick = (url: string) => {
        const redirectUrl = `${window.location.origin}/${url}`
        window.open(redirectUrl, '_blank')
    }

    const handleBannerAction = (key: string) => {
        setShowBanner(false)
        setCompliancePolicyStatus({ action: key })
        if (timeoutInstance) {
            clearTimeout(timeoutInstance)
        }
        setTimeoutInstance(null)
    }

    return (
        <div
            className="flex flex-middle flex-center"
            style={{
                gap: 4,
                height: 56,
                backgroundColor: '#FFF1EC',
                position: 'fixed',
                bottom:0,
                width:'100%',
                left: 0,
                zIndex:'1'
            }}
        >
            <InfoIcon color="#fff" height={'24'} width={'24'} />
            <div className="c-neutral-700 fs-14">{COMPLIANCE_TEXT}</div>
            <div
                className="c-secondary-500 fw-600 fs-14 hover:td-underline c-pointer"
                onClick={() => handleLinkClick('privacy-policy')}
            >
                Privacy Policy
            </div>
            <div className="c-neutral-700 fw-500 fs-14">and</div>
            <div
                className="c-secondary-500 fw-600 fs-14 hover:td-underline c-pointer"
                onClick={() => handleLinkClick('terms-of-service')}
            >
                T&C
            </div>
            <div className="fw-600 pl-2 fs-14 td-underline c-pointer" onClick={() => handleBannerAction('ACCEPT')}>
                Accept
            </div>
            <div
                style={{ position: 'absolute', right: 16 }}
                className="c-pointer"
                onClick={() => handleBannerAction('REJECT')}
            >
                <Cross height={24} width={24} />
            </div>
        </div>
    )
}

export default ComplianceBanner
